import React from "react";
import "./footer.scss";
import { Link } from "react-router-dom";

import { CgArrowLongRight } from "react-icons/cg";

// image import
import line from "../../assets/images/footer/fline.png";
import masterCard from "../../assets/images/paymentCards/masterCard.png";
import visaCard from "../../assets/images/paymentCards/visaCard.png";
import bkashCard from "../../assets/images/paymentCards/bkashCard.png";
import rocketCard from "../../assets/images/paymentCards/rocketCard.png";
import nagadCard from "../../assets/images/paymentCards/nagadCard.png";

export default function Footer() {
  return (
    <div className="footer">
      <div className="section fooetrTop__container">
        <div className="info">
          <div className="footer__companyNameHeader">
            <span>TOCOMA Limited</span>
          </div>

          <div className="footer__companyDetailsTextAndIconContainer">
            <div className="footer__companyDetailsIconr">
              <i className="fas fa-map-marker-alt"></i>
            </div>

            <div className="footer__companyDetailsTextWithHeader">
              <span className="footer__smtext">
                1005 Mailbagh Chowdhury para, Dhaka-1219, Bangladesh
              </span>
            </div>
          </div>

          <div className="footer__companyDetailsTextAndIconContainer">
            <div className="footer__companyDetailsIconr">
              <i className="fa-solid fa-envelope"></i>
            </div>
            <div className="footer__companyDetailsText">
              <a
                className="footer__smtext"
                href="mailto:info@tocoma.bd.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                support@tocoma.com.bd
              </a>
            </div>
          </div>

          <div className="footer__companyDetailsTextAndIconContainer">
            <div className="footer__companyDetailsIconr">
              <i className="fa-solid fa-phone"></i>
            </div>
            <div className="footer__companyDetailsText">
              {/* <a className="footer__smtext" href="tel:+8801812120000">
                +8801812120000,
              </a> */}
              <a className="footer__smtext" href="tel:+8801767456459">
                +88 01812120000
              </a>
            </div>
          </div>

          <div className="footer__socialIconContainer">
            <a
              className="footer__socialIcon"
              href="https://twitter.com/tocoma_bd"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-twitter"></i>
            </a>
            <a
              className="footer__socialIcon"
              href="https://www.facebook.com/tocoma.bd"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook-f"></i>
            </a>
            <a
              className="footer__socialIcon"
              href="https://www.instagram.com/tocoma.bd/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              className="footer__socialIcon"
              href="https://www.linkedin.com/company/tocoma-bd/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-linkedin-in"></i>
            </a>
          </div>
        </div>

        <div className="links">
          <div className="footer__linkHeader">
            <h1>Quick Links</h1>
          </div>

          <div className="footer__linkContainer">
            <Link to="/about" className="footer__link">
              {/* <CgArrowLongRight /> */}
              Terms & Conditions
            </Link>
            <Link to="/services" className="footer__link">
              {/* <CgArrowLongRight /> */}
              Privacy Policy
            </Link>
            <Link to="/services" className="footer__link">
              {/* <CgArrowLongRight /> */}
              Return Policy
            </Link>
            <Link to="/services" className="footer__link">
              {/* <CgArrowLongRight /> */}
              Product Warranty
            </Link>
            <Link to="/services" className="footer__link">
              {/* <CgArrowLongRight /> */}
              Business Hours
            </Link>
            <Link to="/services" className="footer__link">
              {/* <CgArrowLongRight /> */}
              Contact
            </Link>
            <Link to="/services" className="footer__link">
              {/* <CgArrowLongRight /> */}
              Warranty Policy
            </Link>
            <Link to="/services" className="footer__link">
              {/* <CgArrowLongRight /> */}
              Discount Products
            </Link>
            <Link to="/services" className="footer__link">
              {/* <CgArrowLongRight /> */}
              Clearance Sales
            </Link>
            <Link to="/services" className="footer__link">
              {/* <CgArrowLongRight /> */}
              Payment Methods
            </Link>
            <Link to="/services" className="footer__link">
              {/* <CgArrowLongRight /> */}
              Promotional Products
            </Link>
          </div>
        </div>

        <div className="links">
          <div className="footer__linkHeader">
            <h1>Payment Methods</h1>
          </div>

          <div className="paymentcards">
            <img src={masterCard} alt="" />
            <img src={visaCard} alt="" />
            <img src={bkashCard} alt="" />
            <img src={rocketCard} alt="" />
            <img src={nagadCard} alt="" />
          </div>
        </div>

        <div className="logo">
          <div className="footer__topContainer__right__img">
            <img
              src={require("../../assets/images/footer/BuildingBd.png")}
              alt="logo"
            />
          </div>
        </div>
      </div>

      <div className="footer__bottomContainer">
        <div className="section ss">
          <span>© Copyright Tocoma Limited 2022. All Right Reserved</span>
        </div>

        <img src={line} alt="logo" />
      </div>
    </div>
  );
}
