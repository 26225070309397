import brand1 from "../images/brands/Denka-Logo.png";
import brand2 from "../images/brands/OXERRA_Logo_1 1.png";
import brand3 from "../images/brands/Corkjoint 1.png";
import brand4 from "../images/brands/Fastfix Logo 1.png";
import brand5 from "../images/brands/Weber-Logo.png";
// import brand6 from "../../../assets/brands/Frame 6.png";

const brands = [
  {
    id: 1,
    name: "Denka",
    img: brand1,
  },
  {
    id: 2,
    name: "Oxerra",
    img: brand2,
  },
  {
    id: 3,
    name: "Fastfix",
    img: brand3,
  },
  {
    id: 4,
    name: "Corkjoint",
    img: brand4,
  },
  {
    id: 5,
    name: "Weber",
    img: brand5,
  },
];

export default brands;
