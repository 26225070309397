import React from "react";
import "./subscribe.scss";

const Subscribe = () => {
  return (
    <div className="block subscribe">
      <span className="subscribe__title">
        Subscribe us for our Product brochure
      </span>

      <span className="subscribe__subTitle">
        ...and receive 10% coupon for first shopping
      </span>

      <div className="subscribe__btnContainer">
        <input type="text" placeholder="info@tocoma.com.bd" />
        <button className="subscribe__btn">Subscribe</button>
      </div>
    </div>
  );
};

export default Subscribe;
