import React from "react";
import "./landingPage.scss";

import brands from "../../assets/data/brandData";
import newArrivalData from "../../assets/data/newArrivalData";
import featuredData from "../../assets/data/featureData";
import topSellingData from "../../assets/data/topSellingData";

// components import
import Carousel from "../../components/carousel/Carousel";
import Advertisement from "../../components/advertisements/advertisement/Advertisement";
import TextIconBtn from "../../components/buttons/textIconBtn/TextIconBtn";

// icons import
import {
  BsShare,
  BsStarFill,
  BsStarHalf,
  BsStar,
  BsCart3,
} from "react-icons/bs";
import { FiHeart } from "react-icons/fi";

//images import
import dealImg from "../../assets/images/langing/deals/deal-image1.png";
import productBg from "../../assets/images/productBackgrounds/product-bg.png";
import spImg from "../../assets/images/langing/deals/special-deal.png";
import spImg1 from "../../assets/images/langing/deals/special-deal1.png";
import spImg2 from "../../assets/images/langing/deals/special-deal2.png";

// components import
import Subscribe from "../../components/pageComponents/subscribe/Subscribe";
import ProductCarousel from "../../components/pageComponents/productCarousel/ProductCarousel";
import ProductByBrand from "../../components/pageComponents/productByBrand/ProductByBrand";
import Wrapper from "../../components/common/wrapper/Wrapper";
import AppLayout from "../../components/common/appLayout/AppLayout";

const LandingPage = () => {
  return (
    <AppLayout>
      <div className="pageLayout landingPage">
        <Carousel />

        {/* <div className="section"> */}
        <Wrapper>
          <Advertisement />

          <ProductCarousel
            title={"New Arrival"}
            data={newArrivalData}
            iconHeader={true}
          />

          <div className="block landing__deals">
            <div className="textButton__header">
              <span className="textButton__headertext">Deal of The Day</span>

              <div className="textButton__headerButtons">
                <TextIconBtn name="Explore All" />
              </div>
            </div>

            <div className="landing__dealsContainer">
              <div className="deals__left">
                <img src={dealImg} alt="" />

                <div className="deals__leftContent">
                  <div className="deals__leftContent__top">
                    <span className="deals__leftContent__topTitle">New</span>
                    <BsShare />
                  </div>

                  <div className="deals__leftContent__header">
                    <span className="deals__leftContent__headerText">
                      Up to 15%
                    </span>
                  </div>

                  <div className="deals__leftContent__details">
                    <div className="deals__timeCount">
                      <div className="deals__count">
                        <span className="deals__countNumber">07</span>
                        <span className="deals__countText">Days</span>
                      </div>

                      <div className="deals__count">
                        <span className="deals__countNumber">14</span>
                        <span className="deals__countText">Hours</span>
                      </div>

                      <div className="deals__count">
                        <span className="deals__countNumber">31</span>
                        <span className="deals__countText">Mins</span>
                      </div>

                      <div className="deals__count">
                        <span className="deals__countNumber">16</span>
                        <span className="deals__countText">Secs</span>
                      </div>
                    </div>

                    <span className="deals__boldtext">
                      Anti-Corrosive Epoxy and Waterproofing
                    </span>
                    <span className="deals__desctext">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                      Sed blandit felis augue, in ornare magna tincidunt
                      efficitur. Phasellus fermentum pharetra eleifend. Morbi
                      pharetra fringilla erat, eget finibus tortor feugiat a.
                    </span>
                  </div>
                </div>
              </div>

              <div className="deals__right">
                <div
                  className="singleDeal"
                  style={{
                    backgroundImage: `url(${productBg})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  <BsShare />

                  <div className="singleDeal__content">
                    <div className="singleDeal__contentLeft">
                      <span className="singleDeal__title">
                        Fast setting dry cement mortar
                      </span>

                      <div className="singleDeal__rating">
                        <BsStarFill />
                        <BsStarFill />
                        <BsStarFill />
                        <BsStarHalf />
                        <BsStar />
                      </div>

                      <span className="singleDeal__priceText">13000 TK</span>
                      <span className="singleDeal__brand">Brand: TOCOMA</span>

                      <div className="singleDeal__icons">
                        <FiHeart />
                        <BsCart3 />
                      </div>
                    </div>

                    <div className="singleDeal__contentRight">
                      <img src={spImg} alt="" />
                    </div>
                  </div>
                </div>

                <div
                  className="singleDeal"
                  style={{
                    backgroundImage: `url(${productBg})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  <BsShare />

                  <div className="singleDeal__content">
                    <div className="singleDeal__contentLeft">
                      <span className="singleDeal__title">
                        Fast setting dry cement mortar
                      </span>

                      <div className="singleDeal__rating">
                        <BsStarFill />
                        <BsStarFill />
                        <BsStarFill />
                        <BsStarHalf />
                        <BsStar />
                      </div>

                      <span className="singleDeal__priceText">13000 TK</span>
                      <span className="singleDeal__brand">Brand: TOCOMA</span>

                      <div className="singleDeal__icons">
                        <FiHeart />
                        <BsCart3 />
                      </div>
                    </div>

                    <div className="singleDeal__contentRight">
                      <img src={spImg1} alt="" />
                    </div>
                  </div>
                </div>

                <div
                  className="singleDeal"
                  style={{
                    backgroundImage: `url(${productBg})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  <BsShare />

                  <div className="singleDeal__content">
                    <div className="singleDeal__contentLeft">
                      <span className="singleDeal__title">
                        Fast setting dry cement mortar
                      </span>

                      <div className="singleDeal__rating">
                        <BsStarFill />
                        <BsStarFill />
                        <BsStarFill />
                        <BsStarHalf />
                        <BsStar />
                      </div>

                      <span className="singleDeal__priceText">13000 TK</span>
                      <span className="singleDeal__brand">Brand: TOCOMA</span>

                      <div className="singleDeal__icons">
                        <FiHeart />
                        <BsCart3 />
                      </div>
                    </div>

                    <div className="singleDeal__contentRight">
                      <img src={spImg2} alt="" />
                    </div>
                  </div>
                </div>

                <div
                  className="singleDeal"
                  style={{
                    backgroundImage: `url(${productBg})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                >
                  <BsShare />

                  <div className="singleDeal__content">
                    <div className="singleDeal__contentLeft">
                      <span className="singleDeal__title">
                        Fast setting dry cement mortar
                      </span>

                      <div className="singleDeal__rating">
                        <BsStarFill />
                        <BsStarFill />
                        <BsStarFill />
                        <BsStarHalf />
                        <BsStar />
                      </div>

                      <span className="singleDeal__priceText">13000 TK</span>
                      <span className="singleDeal__brand">Brand: TOCOMA</span>

                      <div className="singleDeal__icons">
                        <FiHeart />
                        <BsCart3 />
                      </div>
                    </div>

                    <div className="singleDeal__contentRight">
                      <img src={spImg} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ProductByBrand
            title={"Products By Brand"}
            data={featuredData}
            brandData={brands}
          />

          <ProductCarousel
            title={"Top Selling Products"}
            data={topSellingData}
            carouselTiming={3000}
          />

          <ProductCarousel
            title={"Featured Products"}
            data={featuredData}
            carouselTiming={4500}
          />
        </Wrapper>
        {/* </div> */}

        <Subscribe />
      </div>
    </AppLayout>
  );
};

export default LandingPage;
