import banner1 from "../../assets/images/langing/hero_banner.png";

const carouselData = [
  {
    id: 1,
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    img: banner1,
  },
  {
    id: 2,
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    img: banner1,
  },
  {
    id: 3,
    title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    img: banner1,
  },
];

export default carouselData;
