import React, { useEffect, useState } from "react";
import "./categoriesPage.scss";

import { AnimatePresence } from "framer-motion";

// icons imports
import { BiMenuAltLeft } from "react-icons/bi";
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { BsDash } from "react-icons/bs";
import { GrFormCheckmark } from "react-icons/gr";

// data imports
import featuredData from "../../assets/data/featureData";
import brands from "../../assets/data/brandData";
import topSellingData from "../../assets/data/topSellingData";

// components imports
import ProductCard from "../../components/cards/productCard/ProductCard";
import ProductView from "../../components/pageComponents/productView/ProductView";
import Subscribe from "../../components/pageComponents/subscribe/Subscribe";
import { useLocation } from "react-router-dom";
import ProductCarousel from "../../components/pageComponents/productCarousel/ProductCarousel";
import AppLayout from "../../components/common/appLayout/AppLayout";
import Wrapper from "../../components/common/wrapper/Wrapper";

const CategoriesPage = () => {
  const location = useLocation();
  const state = location.state;

  console.log("state", state);

  const [menu, setMenu] = useState(state ? state : "All");
  const [filteredData, setFilteredData] = useState([]);
  console.log(menu);

  const handleFilterBtn = (name) => {
    setMenu(name);
  };

  useEffect(() => {
    if (menu === "All") {
      setFilteredData(featuredData);
    } else {
      const filtered = featuredData.filter((item) => item.brand === menu);
      setFilteredData(filtered);
    }
  }, [menu]);

  return (
    <AppLayout>
      <div className="pageLayout categoriesPage">
        {/* <div className="categoriesPage__container"> */}
        <Wrapper>
          <div className="block categoriesPage__filterContainer">
            <div className="categoriesPage__filter">
              <div className="cat__filterContainer">
                <span className="catFilter__headerText">Filter By Brand</span>

                <div className="catFilterItem__container">
                  <div
                    className={
                      menu === "All"
                        ? "catFilterItem catFilter__active"
                        : "catFilterItem"
                    }
                    onClick={() => handleFilterBtn("All")}
                  >
                    <div className="catFilterItem__checkBox ">
                      <GrFormCheckmark />
                    </div>
                    <span className="catFilterItem__text">All</span>
                  </div>

                  {brands.map((item) => {
                    return (
                      <div
                        className={
                          item.name === menu
                            ? "catFilterItem catFilter__active"
                            : "catFilterItem"
                        }
                        onClick={() => handleFilterBtn(item.name)}
                      >
                        <div className="catFilterItem__checkBox ">
                          <GrFormCheckmark />
                        </div>
                        <span className="catFilterItem__text">{item.name}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="categoriesPage__content">
              <div className="categoriesPage__headerLinks">
                <span className="categoriesPage__headerLink">
                  Home <MdOutlineKeyboardArrowRight />
                </span>

                <span className="categoriesPage__headerLink">
                  Joint Filler & Sealant
                </span>
              </div>

              <div className="categoriesPage__finteredProducts">
                <AnimatePresence>
                  {filteredData.map((item, index) => {
                    return <ProductCard key={item.id} item={item} />;
                  })}
                </AnimatePresence>
              </div>
            </div>
          </div>

          <ProductCarousel
            title={"Related Product"}
            data={featuredData}
            carouselTiming={3000}
          />

          <ProductCarousel
            title={"Featured Products"}
            data={topSellingData}
            carouselTiming={4500}
          />
        </Wrapper>
        {/* </div> */}

        <Subscribe />
      </div>
    </AppLayout>
  );
};

export default CategoriesPage;
