import React, { useEffect, useState } from "react";
import "./productCarousel.scss";

// icon import
import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// components import
import TextIconBtn from "../../buttons/textIconBtn/TextIconBtn";
import ProductCard from "../../cards/productCard/ProductCard";

const ProductCarousel = ({ title, data, iconHeader, carouselTiming }) => {
  const [swiper, setSwiper] = useState(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  useEffect(() => {
    if (swiper) {
      swiper.on("slideChange", () => {
        if (swiper.isBeginning) {
          setIsBeginning(true);
          setIsEnd(false);
        } else if (swiper.isEnd) {
          setIsEnd(true);
          setIsBeginning(false);
        } else {
          setIsBeginning(false);
          setIsEnd(false);
        }
      });
    }
  }, [swiper]);

  const handleShiftRight = () => {
    if (swiper) {
      swiper.slideNext();
      setIsBeginning(false);
    }

    if (swiper.isEnd) {
      setIsEnd(true);
    }
  };

  const handleShiftLeft = () => {
    if (swiper) {
      swiper.slidePrev();
    }

    if (swiper.isBeginning) {
      setIsBeginning(true);
      setIsEnd(false);
    }
  };

  return (
    <>
      {iconHeader ? (
        <div className="block productCarousel">
          <div className="textIcon__header">
            <span className="textIcon__headertext">{title}</span>

            <div className="textIcon__headerButtons">
              <MdArrowBackIosNew
                className={
                  isBeginning
                    ? "textIcon__headerButton textIcon__headerButton-disabled"
                    : "textIcon__headerButton"
                }
                onClick={handleShiftLeft}
              />

              <MdArrowForwardIos
                className={
                  isEnd
                    ? "textIcon__headerButton textIcon__headerButton-disabled"
                    : "textIcon__headerButton"
                }
                onClick={handleShiftRight}
              />
            </div>
          </div>

          <Swiper
            className="productCarousel__Container"
            slidesPerView={4}
            spaceBetween={24}
            onSwiper={setSwiper}
            autoplay={{
              delay: carouselTiming ? carouselTiming : 4500,
              disableOnInteraction: false,
            }}
            speed={2500}
            modules={[Autoplay, Pagination, Navigation]}
            breakpoints={{
              0: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1100: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
            }}
          >
            {data.map((item, index) => {
              return (
                <SwiperSlide key={index} className="productCarousel__card">
                  <img src={item.img} alt="" />
                  <div className="productCarousel__cardBg">
                    <img src={item.backgroundImg} alt="" />
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      ) : (
        <div className="block productCarousel">
          <div className="textButton__header">
            <span className="textButton__headertext">{title}</span>

            <div className="textButton__headerButtons">
              <TextIconBtn name="Explore All" />
            </div>
          </div>

          <Swiper
            className="productCarousel__Container"
            slidesPerView={4}
            spaceBetween={24}
            onSwiper={setSwiper}
            autoplay={{
              delay: carouselTiming ? carouselTiming : 4500,
              disableOnInteraction: false,
            }}
            speed={2500}
            modules={[Autoplay, Pagination, Navigation]}
            breakpoints={{
              0: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1100: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
            }}
          >
            {data.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <ProductCard item={item} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      )}
    </>
  );
};

export default ProductCarousel;
