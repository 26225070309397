import image1 from "../images/categoryPage/featured/Denka-01 3.png";
import image2 from "../images/categoryPage/featured/Denka-02 2.png";
import image3 from "../images/categoryPage/featured/Denka-03 2.png";
import image4 from "../images/categoryPage/featured/Denka-03 3.png";
import image5 from "../images/categoryPage/Denka-02 3.png";
import image6 from "../images/categoryPage/Flexproof-X1 1.png";
import image7 from "../images/categoryPage/Silent 1.png";
import image8 from "../images/categoryPage/offerProduct/Denka 03 1.png";
import image9 from "../images/categoryPage/offerProduct/Image 1.png";
import image10 from "../images/categoryPage/offerProduct/img.png";

import { RiStarFill, RiStarHalfFill } from "react-icons/ri";

const topSellingData = [
  {
    id: 1,
    image: image1,
    name: "Estopper Hypatite",
    price: 3500,
    discount: "15%",
    discountPrice: 380,
    brand: "Denka",
    rattings: [
      {
        id: 1,
        icon: <RiStarFill />,
      },
      {
        id: 2,
        icon: <RiStarFill />,
      },
      {
        id: 3,
        icon: <RiStarFill />,
      },
      {
        id: 4,
        icon: <RiStarFill />,
      },
      {
        id: 5,
        icon: <RiStarHalfFill />,
      },
    ],
  },
  {
    id: 2,
    image: image2,
    name: "Estopper",
    price: 2300,
    discount: "23%",
    discountPrice: 250,
    brand: "Oxerra",
    rattings: [
      {
        id: 1,
        icon: <RiStarFill />,
      },
      {
        id: 2,
        icon: <RiStarFill />,
      },
      {
        id: 3,
        icon: <RiStarFill />,
      },
      {
        id: 4,
        icon: <RiStarHalfFill />,
      },
      {
        id: 5,
        icon: <RiStarHalfFill />,
      },
    ],
  },
  {
    id: 3,
    image: image3,
    name: "Estopper Hypatite",
    price: 1200,
    discount: "13%",
    discountPrice: 180,
    brand: "Corkjoint",
    rattings: [
      {
        id: 1,
        icon: <RiStarFill />,
      },
      {
        id: 2,
        icon: <RiStarFill />,
      },
      {
        id: 3,
        icon: <RiStarFill />,
      },
      {
        id: 4,
        icon: <RiStarFill />,
      },
      {
        id: 5,
        icon: <RiStarHalfFill />,
      },
    ],
  },
  {
    id: 4,
    image: image4,
    name: "Estotect SWR",
    price: 4000,
    discount: "5%",
    discountPrice: 120,
    brand: "Fastfix",
    rattings: [
      {
        id: 1,
        icon: <RiStarFill />,
      },
      {
        id: 2,
        icon: <RiStarFill />,
      },
      {
        id: 3,
        icon: <RiStarFill />,
      },
      {
        id: 4,
        icon: <RiStarFill />,
      },
      {
        id: 5,
        icon: <RiStarHalfFill />,
      },
    ],
  },
  {
    id: 5,
    image: image5,
    name: "Estopper Hypatite",
    price: 3500,
    discount: "15%",
    discountPrice: 380,
    brand: "Denka",
    rattings: [
      {
        id: 1,
        icon: <RiStarFill />,
      },
      {
        id: 2,
        icon: <RiStarFill />,
      },
      {
        id: 3,
        icon: <RiStarFill />,
      },
      {
        id: 4,
        icon: <RiStarFill />,
      },
      {
        id: 5,
        icon: <RiStarHalfFill />,
      },
    ],
  },
  {
    id: 6,
    image: image6,
    name: "Estopper",
    price: 2300,
    discount: "23%",
    discountPrice: 250,
    brand: "Oxerra",
    rattings: [
      {
        id: 1,
        icon: <RiStarFill />,
      },
      {
        id: 2,
        icon: <RiStarFill />,
      },
      {
        id: 3,
        icon: <RiStarFill />,
      },
      {
        id: 4,
        icon: <RiStarHalfFill />,
      },
      {
        id: 5,
        icon: <RiStarHalfFill />,
      },
    ],
  },
  {
    id: 7,
    image: image7,
    name: "Estopper Hypatite",
    price: 1200,
    discount: "13%",
    discountPrice: 180,
    brand: "Corkjoint",
    rattings: [
      {
        id: 1,
        icon: <RiStarFill />,
      },
      {
        id: 2,
        icon: <RiStarFill />,
      },
      {
        id: 3,
        icon: <RiStarFill />,
      },
      {
        id: 4,
        icon: <RiStarFill />,
      },
      {
        id: 5,
        icon: <RiStarHalfFill />,
      },
    ],
  },
  {
    id: 8,
    image: image8,
    name: "Estopper",
    price: 4000,
    discount: "5%",
    discountPrice: 120,
    brand: "Fastfix",
    rattings: [
      {
        id: 1,
        icon: <RiStarFill />,
      },
      {
        id: 2,
        icon: <RiStarFill />,
      },
      {
        id: 3,
        icon: <RiStarFill />,
      },
      {
        id: 4,
        icon: <RiStarFill />,
      },
      {
        id: 5,
        icon: <RiStarHalfFill />,
      },
    ],
  },
  {
    id: 9,
    image: image9,
    name: "Estopper Hypatite",
    price: 1200,
    discount: "13%",
    discountPrice: 180,
    brand: "Corkjoint",
    rattings: [
      {
        id: 1,
        icon: <RiStarFill />,
      },
      {
        id: 2,
        icon: <RiStarFill />,
      },
      {
        id: 3,
        icon: <RiStarFill />,
      },
      {
        id: 4,
        icon: <RiStarFill />,
      },
      {
        id: 5,
        icon: <RiStarHalfFill />,
      },
    ],
  },
  {
    id: 10,
    image: image10,
    name: "Estopper",
    price: 4000,
    discount: "5%",
    discountPrice: 120,
    brand: "Fastfix",
    rattings: [
      {
        id: 1,
        icon: <RiStarFill />,
      },
      {
        id: 2,
        icon: <RiStarFill />,
      },
      {
        id: 3,
        icon: <RiStarFill />,
      },
      {
        id: 4,
        icon: <RiStarFill />,
      },
      {
        id: 5,
        icon: <RiStarHalfFill />,
      },
    ],
  },
];

export default topSellingData;
