import product1 from "../images/productImages/new products/product1.png";
import product2 from "../images/productImages/new products/product2.png";
import product3 from "../images/productImages/new products/product3.png";

import bg from "../images/productBackgrounds/product-bg-special.png";
import bg1 from "../images/productBackgrounds/special-product-bg2.png";
import bg2 from "../images/productBackgrounds/special-product-bg3.png";
import bg3 from "../images/productBackgrounds/special-product-bg4.png";

const newArrivalData = [
  {
    id: 1,
    img: product1,
    backgroundImg: bg,
    title: "Product Name",
  },
  {
    id: 2,
    img: product2,
    backgroundImg: bg1,
    title: "Product Name",
  },
  {
    id: 3,
    img: product3,
    backgroundImg: bg2,
    title: "Product Name",
  },
  {
    id: 4,
    img: product1,
    backgroundImg: bg3,
    title: "Product Name",
  },
  {
    id: 1,
    img: product1,
    backgroundImg: bg,
    title: "Product Name",
  },
  {
    id: 2,
    img: product2,
    backgroundImg: bg1,
    title: "Product Name",
  },
  {
    id: 3,
    img: product3,
    backgroundImg: bg2,
    title: "Product Name",
  },
  {
    id: 4,
    img: product1,
    backgroundImg: bg3,
    title: "Product Name",
  },
];

export default newArrivalData;
