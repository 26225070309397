import React, { useState, useCallback } from "react";
import "./auth.scss";
import { Link, useNavigate } from "react-router-dom";

// api
import { login } from "../../apis/auth.apis";

// services
import { saveStorage } from "../../utils/persistLocalStorage";

const LoginPage = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState("");

  const [loginFormData, setLoginFormData] = useState({
    LOGIN_ID: "",
    PASSWORD: "",
  });

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setLoginFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // eslint-disable-next-line
  }, []);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();

      setLoading(true);
      login(loginFormData)
        .then((res) => {
          console.log("res", res);
          setLoading(false);
          saveStorage("user", res?.data);
          navigate("/");
        })
        .catch((err) => {
          console.log("err", err.response.data.message);
          setErrorText(err?.response?.data?.message);
          setLoading(false);
        });

      // navigate("/");
      // console.log("Login form submitted:", loginFormData);
    },
    [loginFormData]
  );

  return (
    <div className="authPage">
      <div className="authContainer">
        <div className="auth__header">
          <span className="auth__headerText">Login</span>
        </div>

        <form className="authForm" onSubmit={handleSubmit}>
          <div className="auth__formGroup">
            <input
              className="authinput"
              type="text"
              id="LOGIN_ID"
              name="LOGIN_ID"
              value={loginFormData.LOGIN_ID}
              onChange={handleInputChange}
              placeholder="Username"
              required
            />
          </div>

          <div className="auth__formGroup">
            <input
              className="authinput"
              type="password"
              id="PASSWORD"
              name="PASSWORD"
              value={loginFormData.PASSWORD}
              onChange={handleInputChange}
              placeholder="Password"
              required
            />
          </div>

          <div className="auth__forgetPass">
            <span>Forget password?</span>
          </div>

          {errorText && <span className="auth__errorText">{errorText}</span>}

          <button type="submit" className="authBtn">
            Login
          </button>
        </form>

        <div className="auth__sub">
          <span>Don't have an account?</span>

          <Link to="/sign-up" className="auth__subLink">
            Sign Up
          </Link>
        </div>

        <div className="authBottom"></div>
      </div>
    </div>
  );
};

export default LoginPage;
