import React, { useState, useCallback } from "react";
import "./auth.scss";
import { Link, useNavigate } from "react-router-dom";

// api
import { signup } from "../../apis/auth.apis";

const RegisterPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState("");

  const [regFormData, setRegFormData] = useState({
    EMAIL: "",
    LOGIN_ID: "",
    PASSWORD: "",
    confirmPassword: "",
  });
  // console.log("data", regFormData);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setRegFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // eslint-disable-next-line
  }, []);

  // validate form input data
  const validateForm = useCallback(() => {
    if (regFormData.PASSWORD.length < 3) {
      setErrorText("Password must be at least 3 characters");
      return false;
    }
    if (regFormData.PASSWORD !== regFormData.confirmPassword) {
      setErrorText("Passwords do not match");
      return false;
    }

    setErrorText("");
    return true;
  }, [regFormData]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);

      if (!validateForm()) return;

      signup(regFormData)
        .then((res) => {
          console.log("res", res);

          setErrorText(res.data.Message);

          setTimeout(() => {
            if (res.status === 200) {
              navigate("/login");
            }
          }, 3000);

          setLoading(false);
        })
        .catch((err) => {
          console.log("err", err);
          setErrorText(
            err?.response?.data?.message ||
              "Something went wrong. Please try again later"
          );
          setLoading(false);
        });

      // navigate("/login");
      // console.log("Registration form submitted:", regFormData);
    },
    [regFormData, validateForm]
  );

  return (
    <div className="authPage">
      <div className="authContainer">
        <div className="auth__header">
          <span className="auth__headerText">Sign Up</span>
        </div>

        <form className="authForm" onSubmit={handleSubmit}>
          <div className="auth__formGroup">
            <input
              className="authinput"
              type="email"
              id="EMAIL"
              name="EMAIL"
              value={regFormData.EMAIL}
              onChange={handleInputChange}
              placeholder="Email"
              required
            />
          </div>

          <div className="auth__formGroup">
            <input
              className="authinput"
              type="text"
              id="LOGIN_ID"
              name="LOGIN_ID"
              value={regFormData.LOGIN_ID}
              onChange={handleInputChange}
              placeholder="Username"
              required
            />
          </div>

          <div className="auth__formGroup">
            <input
              className="authinput"
              type="number"
              id="PHONE"
              name="PHONE"
              value={regFormData.PHONE}
              onChange={handleInputChange}
              placeholder="Phone No"
              required
            />
          </div>

          <div className="auth__formGroup">
            <input
              className="authinput"
              type="password"
              id="PASSWORD"
              name="PASSWORD"
              value={regFormData.PASSWORD}
              onChange={handleInputChange}
              placeholder="Password"
              required
            />
          </div>

          <div className="auth__formGroup">
            <input
              className="authinput"
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              value={regFormData.confirmPassword}
              onChange={handleInputChange}
              placeholder="Confirm Password"
              required
            />
          </div>

          {errorText && <span className="auth__errorText">{errorText}</span>}

          <button type="submit" className="authBtn">
            Register
          </button>
        </form>

        <div className="auth__sub">
          <span>Already have an account?</span>

          <Link to="/login" className="auth__subLink">
            Login
          </Link>
        </div>

        <div className="authBottom"></div>
      </div>
    </div>
  );
};

export default RegisterPage;
