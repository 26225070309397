import React, { useEffect, useState } from "react";
import "./productByBrand.scss";

import { AnimatePresence } from "framer-motion";

import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";

import TextIconBtn from "../../buttons/textIconBtn/TextIconBtn";
import ProductCard from "../../cards/productCard/ProductCard";

const ProductByBrand = ({ title, data, brandData }) => {
  const [activeBrand, setActiveBrand] = useState("Denka");
  const [filteredData, setFilteredData] = useState([]);

  const handleClickedBrand = (name) => {
    setActiveBrand(name);
  };
  // console.log(activeBrand);

  useEffect(() => {
    if (activeBrand === "All") {
      setFilteredData(data);
    } else {
      const filtered = data.filter((item) => item.brand === activeBrand);
      setFilteredData(filtered);
    }
  }, [activeBrand, data]);

  return (
    <div className="block brandProduct">
      <div className="textButton__header">
        <span className="textButton__headertext">{title}</span>

        <div className="textButton__headerButtons">
          <TextIconBtn name="Explore All" />
        </div>
      </div>

      <div className="brandProductContainer">
        <AnimatePresence>
          {filteredData.map((item) => {
            return <ProductCard key={item.id} item={item} />;
          })}
        </AnimatePresence>
      </div>

      <div className="brandList">
        {brandData?.map((item) => {
          return (
            <div
              key={item.id}
              className={
                activeBrand === item.name
                  ? "brandList__item active_brandList__item"
                  : "brandList__item"
              }
              onClick={() => handleClickedBrand(item.name)}
            >
              <img src={item.img} alt="" />
            </div>
          );
        })}

        <div className="brandArrowLeft">
          <MdArrowBackIosNew />
        </div>

        <div className="brandArrowRight">
          <MdArrowForwardIos />
        </div>
      </div>
    </div>
  );
};

export default ProductByBrand;
