import React from "react";
import "./errorPage.scss";
import AppLayout from "../../components/common/appLayout/AppLayout";

const ErrorPage = () => {
  return (
    <AppLayout>
      <div className="errorPage">Error</div>
    </AppLayout>
  );
};

export default ErrorPage;
