import React from "react";
import "./productCard.scss";
import { Link } from "react-router-dom";

import { motion } from "framer-motion";

// icons import
import { FiHeart } from "react-icons/fi";
import { BsCart3 } from "react-icons/bs";

// services
import { useCart } from "../../../context/cart.context";
import { saveStorage } from "../../../utils/persistLocalStorage";

const ProductCard = ({ item }) => {
  const [cart, setCart] = useCart();

  return (
    <motion.div
      layout
      initial={{
        opacity: 0,
      }}
      animate={{
        opacity: 1,
      }}
      exit={{
        opacity: 0,
      }}
      transition={{
        duration: 0.4,
      }}
      key={item?.id}
    >
      <Link to="/product-details" className="productCard">
      <div className="productCard">
        <div className="productCard__top">
          <span className="productCard__text">{item?.discount}</span>

          {/* <div className="productCard__topRating">
            {item?.rattings?.map((ratting, index) => {
              return <span key={index}>{ratting.icon}</span>;
            })}
          </div> */}
        </div>

        <div className="productCard__info">
          <span className="productCard__name">{item?.name}</span>
          <span className="productCard__price">
            Price: {item?.price} BDT <span>{item?.discountPrice}</span>
          </span>
        </div>

        <div className="productCard__img">
          <img src={item?.image} alt="" />
        </div>

        <div className="productCard__icons">
          <div className="productCard__icon">
            <FiHeart />
          </div>
          <button
            className="productCard__icon"
            onClick={() =>
              setCart((prev) => {
                let existingItem = prev.find(
                  (cartItem) => cartItem.id === item.id
                );
                if (existingItem) {
                  existingItem.quantity += 1;
                  saveStorage("cart", prev);
                  return [...prev];
                } else {
                  let newItem = { ...item, quantity: 1 };
                  saveStorage("cart", [...prev, newItem]);
                  return [...prev, newItem];
                }
              })
            }
          >
            <BsCart3 />
          </button>
        </div>
      </div>
      </Link>
    </motion.div>
  );
};

export default ProductCard;
