import React, { useEffect, useState } from "react";
import "./productDetailsPage.scss";

// icon imports
import { AiOutlineHeart } from "react-icons/ai";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";
import {
  MdArrowOutward,
  MdOutlineArrowDropDown,
  MdOutlineArrowDropUp,
} from "react-icons/md";
import { RiStarFill, RiStarHalfFill } from "react-icons/ri";
import { BsPencilSquare } from "react-icons/bs";

// image imports
import card1 from "../../assets/images/paymentCards/Mastercard-trans.png";
import card2 from "../../assets/images/paymentCards/Mastercard-trans.png";
import card3 from "../../assets/images/paymentCards/Visa-trans.png";
import card4 from "../../assets/images/paymentCards/bkash-trans.png";
import card5 from "../../assets/images/paymentCards/rocketCard.png";

// component imports
import ProductCarousel from "../../components/pageComponents/productCarousel/ProductCarousel";
import Subscribe from "../../components/pageComponents/subscribe/Subscribe";
import AppLayout from "../../components/common/appLayout/AppLayout";

// data imports
import singleProductData from "../../assets/data/singleProductData";
import featuredProduct from "../../assets/data/featureData";

const ProductDetailsPage = () => {
  const [activeImage, setActiveImage] = useState(0);
  const [quantity, setQuantity] = useState(1);

  const increaseQuantity = () => {
    setQuantity(quantity + 1);
  };

  const decreaseQuantity = () => {
    if (quantity <= 0) return;
    setQuantity(quantity - 1);
  };

  const [activeTab, setActiveTab] = useState("description");
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const text = document.querySelector(".pd__detailsText span");
    text.classList.add("active");

    setTimeout(() => {
      text.classList.remove("active");
    }, 500);
  }, [activeTab]);

  return (
    <AppLayout>
      <div className="pageLayout productDetailsPage">
        <div className="productDetailsPage__container">
          <div className="section productDetailsPage__content1">
            <div className="product__container">
              <div className="product__containerLeft">
                <div className="pcl__mainImage">
                  <img src={singleProductData.images[activeImage].img} alt="" />
                </div>

                <div className="pcl__subImages">
                  {singleProductData.images.map((img, index) => (
                    <div
                      className="pcl__subImage"
                      onClick={() => setActiveImage(index)}
                    >
                      <div className="pcl__subImage__icon">
                        <MdArrowOutward />
                      </div>

                      <img src={img.img} alt="" />
                    </div>
                  ))}
                </div>
              </div>

              <div className="product__containerRight">
                <span className="ptoduct__name">Estopper Hypatite</span>

                <div className="product__ratingReview">
                  <div className="product__stars">
                    <RiStarFill />
                    <RiStarFill />
                    <RiStarFill />
                    <RiStarFill />
                    <RiStarHalfFill />
                  </div>

                  <div className="product__review">
                    <span>4.5</span>
                    <span>(23 Reviews)</span>
                  </div>

                  <div className="product__writeReview">
                    <BsPencilSquare />
                    <span>Write a Review</span>
                  </div>
                </div>

                <div className="product__pricing">
                  <span className="product__price">$ 20.00</span>
                  <span className="product__discountPrice">$ 15.00</span>
                  <div className="saving__percentage">
                    <span>saving 20%</span>
                  </div>
                </div>

                <div className="product__desc">
                  <span>
                    Estopper Hypatite Elastomeric Membrane System for Expansion
                    Joint Waterproofing. Brand: Denka from Japan.
                  </span>
                </div>

                <div className="product__quanAddFev">
                  <div className="product__quantity">
                    <input type="text" name="" id="" value={quantity} />

                    <div className="product__quantity__btns">
                      <MdOutlineArrowDropUp onClick={increaseQuantity} />
                      <MdOutlineArrowDropDown onClick={decreaseQuantity} />
                    </div>
                  </div>

                  <div className="product__add">
                    <span>Add to cart</span>
                  </div>

                  <div className="product__fav">
                    <AiOutlineHeart />
                  </div>
                </div>

                <div className="product__shares">
                  <span>Share</span>
                  <FaFacebookF />
                  <FaInstagram />
                  <FaTwitter />
                  <FaLinkedinIn />
                </div>

                <div className="product__payments">
                  <span className="product__paymentsText">Payment Methods</span>

                  <div className="product__paymentcards">
                    <img src={card1} alt="" />
                    <img src={card2} alt="" />
                    <img src={card3} alt="" />
                    <img src={card4} alt="" />
                    <img src={card5} alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div className="product__detailsContainer">
              <div className="pd__filterContainer">
                <span
                  onClick={() => handleTabChange("description")}
                  className={activeTab === "description" ? "activeTab" : ""}
                >
                  DESCRIPTION
                </span>

                <span
                  onClick={() => handleTabChange("product")}
                  className={activeTab === "product" ? "activeTab" : ""}
                >
                  PRODUCT
                </span>

                <span
                  onClick={() => handleTabChange("reviews")}
                  className={activeTab === "reviews" ? "activeTab" : ""}
                >
                  REVIEWS
                </span>
              </div>

              <div className="pd__detailsText">
                <span className={activeTab === "description" ? "active" : ""}>
                  {
                    {
                      // description: singleProductData.description,
                      description: (
                        <span>
                          Corduroy enhances with the bold color-block gives the
                          long-sleeved shirt an on-trend look and follows the
                          trend for fashion, which is a perfect blend for casual
                          and modern style. The shirt featuring full-length
                          button fastenings and a contrasting pocket patch on
                          the chest provides a laidback and chic look.
                          <br />
                          <br />
                          <h4>Ventilated Cool For</h4>
                          the Trail Woven fabric feels light and smooth. It uses
                          Dri-FIT Technology to help you stay dry and
                          comfortable.
                          <br />
                          <br />
                          <h4>Stash Away A</h4>
                          stash pocket on the upper back can be used to store
                          lightweight essentials.
                          <br />
                          <br />
                          <h4>Product Details</h4>
                          <li>Reflective design details</li>
                          <li>Body: 80% polyester/20% elastane.</li>
                          <li>Panel: 100% polyester.</li>
                          <li>Inset: 92% polyester/8%elastane.</li>
                          <li>Machine wash </li>
                          <li>Imported </li>
                          <li>
                            Not intended for use as Personal Protective
                            Equipment (PPE) Colour{" "}
                          </li>
                          <li>Shown: Hasta Style: CZ9051-387 </li>
                        </span>
                      ),
                      product: singleProductData.product,
                      reviews: singleProductData.reviews,
                    }[activeTab]
                  }
                </span>
              </div>
            </div>

            <ProductCarousel
              title="Related Product"
              data={featuredProduct}
              carouselTiming={3000}
            />
          </div>

          <Subscribe />
        </div>
      </div>
    </AppLayout>
  );
};

export default ProductDetailsPage;
