import React, { useState } from "react";
import "./cartMenu.scss";

// icons import
import { RxCross2 } from "react-icons/rx";

// image import
import product1 from "../../../assets/images/productImages/meyco.png";
import product2 from "../../../assets/images/productImages/Flexproof-X1.png";

// services
import { useCart } from "../../../context/cart.context";

const CartMenu = () => {
  const [cart, setCart] = useCart();

  const withoutDiscount = cart.reduce((a, c) => a + c.discountPrice, 0);
  const withDiscount = cart.reduce((a, c) => a + c.price, 0);
  const totalDiscount = withDiscount - withoutDiscount;

  return (
    <div className="cartContent">
      <div className="cartProduct__container">
        {cart.map((product) => (
          <div className="cartProduct">
            <div className="product__derails">
              <img src={product.image} alt="" className="cartProduct__img" />
              <div className="cartProductDetails">
                <span>{product.name}</span>
                <span>{product.price} BDT</span>
              </div>
            </div>

            <div className="cross__icon">
              <RxCross2
                className="cartProduct__cross"
                onClick={() => {
                  const newCart = cart.filter((pd) => pd.id !== product.id);
                  setCart(newCart);
                }}
              />
            </div>
          </div>
        ))}
      </div>

      <div className="cart__pdt">
        <div className="cart__pd">
          <span>{cart.length} Items</span>
          <span>{withoutDiscount} BDT</span>
        </div>

        <div className="cart__pd">
          <span>Discount</span>
          <span>{totalDiscount} BDT</span>
        </div>
      </div>

      <div className="cart__total">
        <span className="cart__totalText">Total</span>
        <span className="cart__totalText">{withDiscount} BDT</span>
      </div>

      <div className="cart__cBtn">
        <button
          className="cart__btn"
          onClick={() => {
            window.location.href = "/check-out";
          }}
        >
          Checkout
        </button>
      </div>

      <div className="cart__cBtn">
        <button
          className="cart__btn"
          onClick={() => {
            window.location.href = "/order";
          }}
        >
          Place Order
        </button>
      </div>
    </div>
  );
};

export default CartMenu;
