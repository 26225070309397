import React from "react";
import "./checkoutPage.scss";
import { Link } from "react-router-dom";

// components import
import Subscribe from "../../components/pageComponents/subscribe/Subscribe";

// icons import
import { RiDeleteBin6Line } from "react-icons/ri";

// images import
import masterCard from "../../assets/images/paymentCards/Mastercard-trans.png";
import bKash from "../../assets/images/paymentCards/bkash-trans.png";
import nagad from "../../assets/images/paymentCards/Nagad-trans.png";
import visa from "../../assets/images/paymentCards/Visa-trans.png";
// import masterCard from "../../assets/images/paymentCards/Mastercard-trans.png";
import productImg1 from "../../assets/images/productImages/meyco.png";
import productImg2 from "../../assets/images/productImages/meyco.png";
import AppLayout from "../../components/common/appLayout/AppLayout";

const CheckoutPage = () => {
  return (
    <AppLayout>
      <div className="pageLayout checkoutPage">
        <div className="section chectoutContent">
          <div className="block productCheckout">
            <div className="productList">
              <div className="productList__header">
                <span>Item</span>
                <span>Price</span>
                <span>Qnt</span>
                <span>Total Price</span>
                <span></span>
              </div>

              <div className="productList__product">
                <div className="productI">
                  <img src={productImg1} alt="" />
                  <span>Estopper Hypatite</span>
                </div>
                <span>1200 BDT</span>
                <span>Qnt</span>
                <span>2400 BDT</span>
                <RiDeleteBin6Line />
              </div>

              <div className="productList__product">
                <div className="productI">
                  <img src={productImg2} alt="" />
                  <span>Estopper Hypatite</span>
                </div>
                <span>1200 BDT</span>
                <span>Qnt</span>
                <span>2400 BDT</span>
                <RiDeleteBin6Line />
              </div>
            </div>

            <button>Continue Shopping</button>
          </div>

          <div className="block orderSummary">
            <div className="summeryList">
              <div className="row">
                <span>3 Items</span>
                <span>4500 Taka</span>
              </div>

              <div className="row">
                <span>Delivery Charge</span>
                <span>300 Taka</span>
              </div>

              <div className="row">
                <span>Total (Tax Excl.)</span>
                <span>4800 Taka</span>
              </div>

              <div className="row">
                <span>Taxes</span>
                <span>0.00 Taka</span>
              </div>
            </div>

            <Link to="/order">
              <button>Proceed To Checkout</button>
            </Link>

            <div className="paymentMethod">
              <div className="paymentMethod__list">
                <div class="btn">
                  <img src={masterCard} alt="" className="noselect" />
                </div>

                <div class="btn">
                  <img src={bKash} alt="" className="noselect" />
                </div>

                <div class="btn">
                  <img src={visa} alt="" className="noselect" />
                </div>

                <div class="btn">
                  <img src={nagad} alt="" className="noselect" />
                </div>

                <div class="btn">
                  <img src={masterCard} alt="" className="noselect" />
                </div>
              </div>

              <span>Guarantee safe & secure checkout</span>

              {/* <div class="btn">
              <span class="noselect">Framed</span>
            </div> */}
            </div>
          </div>
        </div>

        <Subscribe />
      </div>
    </AppLayout>
  );
};

export default CheckoutPage;
