import React from "react";
import "./orderPage.scss";

// components imports
import Subscribe from "../../components/pageComponents/subscribe/Subscribe";

// image imports
import masterCard from "../../assets/images/paymentCards/masterCard.png";
import visaCard from "../../assets/images/paymentCards/visaCard.png";
import bkash from "../../assets/images/paymentCards/bkashCard.png";
import rocket from "../../assets/images/paymentCards/rocketCard.png";
import nagad from "../../assets/images/paymentCards/nagadCard.png";
import AppLayout from "../../components/common/appLayout/AppLayout";

const OrderPage = () => {
  return (
    <AppLayout>
      <main className="pageLayout orderPage">
        <div className="section">
          <div className="block orderPage__top">
            <div className="order__addresses">
              <div className="order__address">
                <span className="order__addressHeader">Billing Address</span>

                <div className="orderAddress__row">
                  <input type="text" placeholder="Full Name" />
                  <input type="text" placeholder="Mobile" />
                </div>

                <div className="orderAddress__row">
                  <input type="text" placeholder="Division / Province" />
                  <input type="text" placeholder="City / Municipality" />
                </div>

                <div className="orderAddress__row">
                  <input type="text" placeholder="Area" />
                  <input type="text" placeholder="House no: Building, Street" />
                </div>

                <div className="orderAddress__row">
                  <input
                    type="text"
                    placeholder="E.g. beside train station (Optional)"
                  />
                  <input type="text" placeholder="" />
                </div>
              </div>

              <div className="order__address">
                <span className="order__addressHeader">Shipping address</span>

                <div className="orderAddress__row">
                  <input type="text" placeholder="Full Name" />
                  <input type="text" placeholder="Mobile" />
                </div>

                <div className="orderAddress__row">
                  <input type="text" placeholder="Division / Province" />
                  <input type="text" placeholder="City / Municipality" />
                </div>

                <div className="orderAddress__row">
                  <input type="text" placeholder="Area" />
                  <input type="text" placeholder="House no: Building, Street" />
                </div>

                <div className="orderAddress__row">
                  <input
                    type="text"
                    placeholder="E.g. beside train station (Optional)"
                  />
                  <input type="text" placeholder="" />
                </div>
              </div>
            </div>

            <div className="summeryList">
              <div className="summeryList__top">
                <div className="row">
                  <span>3 Items</span>
                  <span>4500 Taka</span>
                </div>

                <div className="row">
                  <span>Delivery Charge</span>
                  <span>300 Taka</span>
                </div>
              </div>

              <div className="summeryList__bottom">
                <div className="row">
                  <span>Total (Tax Excl.)</span>
                  <span>4800 Taka</span>
                </div>

                <div className="row">
                  <span>Taxes</span>
                  <span>0.00 Taka</span>
                </div>
              </div>
            </div>
          </div>

          <div className="block orderPage__payment">
            <span>Select Payment Method</span>

            <div className="orderPage__cards">
              <img src={masterCard} alt="" />
              <img src={visaCard} alt="" />
              <img src={bkash} alt="" />
              <img src={rocket} alt="" />
              <img src={nagad} alt="" />
            </div>

            <div className="cardDetails">
              <input type="text" placeholder="Card Number" />
              <input type="text" placeholder="Card Name" />

              <div className="cardDe__row">
                <input
                  type="number"
                  placeholder="Expiration Date"
                  className="r"
                />
                <input type="text" placeholder="CVV" className="r" />
              </div>

              <div className="saveCard">
                <input type="checkbox" />

                <div className="saveText">
                  <span className="medium">Save Card</span>
                  <span className="small">
                    To make easy payments for future, your card will be saved in
                    your TOCOMA Shop account.
                  </span>
                </div>
              </div>

              <button>Pay Now</button>
            </div>
          </div>
        </div>
        <Subscribe />
      </main>
    </AppLayout>
  );
};

export default OrderPage;
