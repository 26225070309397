import axios from "axios";

import { SIGNUP_URL, LOGIN_URL } from "../utils/urls";

export const signup = async (data) => {
  return axios({
    method: "post",
    url: SIGNUP_URL,
    data: data,
  });
};

export const login = async (data) => {
  return axios({
    method: "post",
    url: LOGIN_URL,
    data: data,
  });
};
