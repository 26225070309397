import React, { useState } from "react";
import "./navbar.scss";
import { Link } from "react-router-dom";

// icons import
import { MdOutlineKeyboardArrowDown } from "react-icons/md";
import { AiOutlineUser } from "react-icons/ai";
import { PiHandbag } from "react-icons/pi";
import { BiSearch } from "react-icons/bi";
import { PiDotsNineBold } from "react-icons/pi";
import { BsGlobe } from "react-icons/bs";
import { BsCart3 } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";

// image import
import logo from "../../assets/images/tocoma__logo.png";
import CartMenu from "./cartMenu/CartMenu";

// services
import { loadStorage } from "../../utils/persistLocalStorage";
const { useCart } = require("../../context/cart.context");

const Navbar = () => {
  const [openCart, setOpenCart] = useState(false);
  const user = loadStorage("user");

  const [cart, setCart] = useCart();

  const handleCart = () => {
    setOpenCart(!openCart);
  };

  return (
    <div className="navbar">
      <div className="navTop">
        <div className=" navTop__container">
          <Link to="/" className="logo">
            <img src={logo} alt="" />
          </Link>

          <div className="navTop__right">
            <div className="navTop__search">
              <input
                type="text"
                placeholder="Search"
                className="navTop__searcText"
              />

              <BiSearch className="navTop__searchIcon" />
            </div>

            <div className="navTopRight__menu mHide">
              <BsGlobe className="navTopRight__menuIcon" />
              <span className="navTopRight__menuText">English</span>
              <MdOutlineKeyboardArrowDown className="navTopRight__down" />
            </div>

            {user ? (
              <Link to="/profile" className="navTopRight__menu mHide">
                <AiOutlineUser className="navTopRight__menuIcon" />
                <span className="navTopRight__menuText">My Account</span>
                <MdOutlineKeyboardArrowDown className="navTopRight__down" />
              </Link>
            ) : (
              <Link to="/login" className="navTopRight__menu mHide">
                <AiOutlineUser className="navTopRight__menuIcon" />
                <span className="navTopRight__menuText">Login</span>
              </Link>
            )}

            <div className="navTopRight__menu">
              <div className="cart" onClick={handleCart}>
                <PiHandbag className="navTopRight__menuIcon" />
                <span className="cartCount">{cart.length}</span>
              </div>
              <span className="navTopRight__menuText">My Cart</span>

              {openCart && (
                <div className="cart__containerDetails">
                  <div className="cart__header">
                    <div className="cart__headerLeft">
                      <BsCart3 />
                      <span>My Cart</span>
                    </div>
                    <div className="cart__headerRight" onClick={handleCart}>
                      <RxCross2 />
                    </div>
                  </div>

                  <CartMenu />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="navBottom">
        <div className=" navBottom__container">
          <div className="navCategory">
            <PiDotsNineBold className="navCategory__icon" />
            <span className="navCategory__text">Product</span>

            <div className="catHover">
              <Link to="/categories" state={"Corkjoint"} className="cat__link">
                Concrete Aditivies
              </Link>
              <Link to="/categories" className="cat__link">
                Underground Engineering
              </Link>
              <span className="cat__link">Flooring System</span>
              <span className="cat__link">High Strength Grouts</span>
              <span className="cat__link">Concrete Repair System</span>
              <span className="cat__link">Joint Filler & Sealants</span>
              <span className="cat__link">Protective Coating</span>
              <span className="cat__link">Bonding and Anchoring Adhesive</span>
            </div>
          </div>

          <div className="navBottom__menu">
            <div className="navBottom__menuItem">
              <span className="navBottom__menuText">Request For</span>
              <MdOutlineKeyboardArrowDown className="navBottom__menuIcon" />
            </div>

            <div className="navBottom__menuItem">
              <span className="navBottom__menuText">Technical Support</span>
            </div>

            <div className="navBottom__menuItem">
              <span className="navBottom__menuText">Contact </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
