import React from "react";
import "./advertisement.scss";

import advertisement from "../../../assets/images/advertisement/topAdd.png";

const Advertisement = () => {
  return (
    <div className="block advertisement">
      <div className="advertisement__textContainer">
        <div className="advertisement__left">
          <span className="advertisement__subText">TOCOMA Shop Mega Deals</span>
          <span className="advertisement__titletext">Enjoy up to 20% Off</span>
        </div>

        <div className="advertisement__right">
          <span className="advertisement__rightText1">Promo code</span>
          <span className="advertisement__rightText2">TOCOMA964825</span>
        </div>
      </div>

      <div className="advertisement__img">
        <img src={advertisement} alt="" />
      </div>
    </div>
  );
};

export default Advertisement;
