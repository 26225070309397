import React from "react";
import "./styles/global.scss";

import { Route, Routes } from "react-router-dom";

import {
  LandingPage,
  CategoriesPage,
  ProductDetailsPage,
  CheckoutPage,
  OrderPage,
  ProfilePage,
  LoginPage,
  RegisterPage,
  ErrorPage,
  TestPage,
} from "./screens/index";

const App = () => {
  return (
    <div className="app">
      {/* <Navbar /> */}

      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/categories" element={<CategoriesPage />} />
        <Route path="/product-details" element={<ProductDetailsPage />} />
        <Route path="/check-out" element={<CheckoutPage />} />
        <Route path="/order" element={<OrderPage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/sign-up" element={<RegisterPage />} />

        <Route path="/test" element={<TestPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>

      {/* <Footer /> */}
    </div>
  );
};

export default App;
