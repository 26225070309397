import React from "react";
import "./carousel.scss";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Navigation, Autoplay, Pagination } from "swiper/modules";

import "swiper/css/pagination";

import carouselData from "./carouselData";

const Carousel = () => {
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '">' + (index + 1) + "</span>";
    },
  };

  return (
    <div className="carousel">
      <Swiper
        className="landing_hero mySwiper"
        pagination={pagination}
        spaceBetween={0}
        slidesPerView={1}
        loop={true}
        navigation
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        speed={2000}
        modules={[Navigation, Autoplay, Pagination]}
      >
        {carouselData.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="landing_heroImg">
                <img src={item.img} alt="" />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default Carousel;
