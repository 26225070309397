import img1 from "../images/productImages/Denka-02.png";
import img2 from "../images/productImages/meyco.png";
import img3 from "../images/productImages/Flexproof-X1.png";

const singleProductData = {
  id: 1,
  name: "Estopper Hypatite",
  price: 4500,
  images: [
    {
      id: 1,
      img: img1,
    },
    {
      id: 2,
      img: img2,
    },
    {
      id: 3,
      img: img3,
    },
    {
      id: 4,
      img: img1,
    },
    {
      id: 5,
      img: img2,
    },
    {
      id: 6,
      img: img3,
    },
  ],
  description:
    "Corduroy enhances with the bold color-block gives the long-sleeved shirt an on-trend look and follows the trend for fashion, which is a perfect blend for casual and modern style. The shirt featuring full-length button fastenings and a contrasting pocket patch on the chest provides a laidback and chic look. Ventilated Cool For the Trail Woven fabric feels light and smooth. It uses Dri-FIT Technology to help you stay dry and comfortable. Stash Away A stash pocket on the upper back can be used to store lightweight essentials. Product Details Reflective design details Body: 80% polyester/20% elastane. Panel: 100% polyester. Inset: 92% polyester/8% elastane. Machine wash Imported Not intended for use as Personal Protective Equipment (PPE) Colour Shown: Hasta Style: CZ9051-387",
  product:
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. ",
  rating: 4,
  reviews: "Total 10 Reviews",
  category: "Coationg",
  stock: 10,
};

export default singleProductData;
