import React, { useState } from "react";
import "./profilePage.scss";

// components imports
import Subscribe from "../../components/pageComponents/subscribe/Subscribe";

// icons import
import { RiDeleteBin6Line } from "react-icons/ri";

// image imports
import productImg1 from "../../assets/images/productImages/meyco.png";
import productImg2 from "../../assets/images/productImages/meyco.png";
import AppLayout from "../../components/common/appLayout/AppLayout";

// services
import { loadStorage, delStorage } from "../../utils/persistLocalStorage";
import { useNavigate } from "react-router-dom";

const ProfilePage = () => {
  const navigate = useNavigate();
  const user = loadStorage("user");

  const [activeTab, setActiveTab] = useState("overview");

  const handleTab = (name) => {
    return () => {
      setActiveTab(name);
    };
  };

  const handleLogout = () => {
    delStorage("user");
    navigate("/login");
  };

  return (
    <AppLayout>
      <div className="pageLayout profilePage">
        <div className="section profilePageContainer">
          <div className="profile__left">
            <div className="profileImgName">
              <img
                src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?cs=srgb&dl=pexels-pixabay-220453.jpg&fm=jpg"
                alt=""
              />

              <span>Abdullah Al Zaber</span>
            </div>

            <div className="manageProfile__container">
              <div className="manageProfile">
                <span className="manageProfile__header">Manage My Account</span>

                <div className="manageProfile__items">
                  <span onClick={handleTab("overview")}>overview</span>
                  <span onClick={handleTab("profile")}>My Profile</span>
                  <span onClick={handleTab("address")}> Address Book</span>
                  <span>My Payment Options</span>
                  <span>Vouchers</span>
                </div>
              </div>

              <div className="manageProfile">
                <span className="manageProfile__header">My Orders</span>

                <div className="manageProfile__items">
                  <span>My Returns</span>
                  <span>My Cancellations</span>
                </div>
              </div>

              {user && (
                <div className="manageProfile">
                  <span className="manageProfile__header">My Account</span>

                  <div className="manageProfile__items">
                    <button onClick={handleLogout}>Logout</button>
                  </div>
                </div>
              )}
            </div>
          </div>

          {activeTab === "overview" ? (
            <div className="overviewContent">
              <div className="overviewContent__top">
                <div className="profileCard">
                  <span className="profileCard__title">Personal Profile</span>

                  <div className="profileCard__items">
                    <span>Abdullah Al Zaber</span>
                    <span>abdullahalzaber58@gmail.com</span>
                    <span>Subscribe to our Newsletter</span>
                  </div>
                </div>

                <div className="profileAddress__container">
                  <div className="profileAddress__card">
                    <span className="Address__cardTitle">Address Book</span>
                    <span className="Address__cardSubTitle">
                      DEFAULT DELIVERY ADDRESS
                    </span>

                    <div className="address">
                      <span className="addressUser">Abdullah al jaber</span>
                      <span>
                        1005 Malibagh Chowdhurypara. Dhaka - Dhaka - South -
                        Malibag (+880) 1932493092
                      </span>
                    </div>
                  </div>

                  <div className="profileAddress__card">
                    <span className="Address__cardTitle">Billing Address</span>
                    <span className="Address__cardSubTitle">
                      DEFAULT billing ADDRESS
                    </span>

                    <div className="address">
                      <span className="addressUser">Abdullah al jaber</span>
                      <span>
                        1005 Malibagh Chowdhurypara. Dhaka - Dhaka - South -
                        Malibag (+880) 1932493092
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="recentOrders">
                <div className="block productCheckout">
                  <div className="productList">
                    <div className="productList__header">
                      <span>Recent Orders</span>
                    </div>

                    <div className="productList__product">
                      <span>Order #</span>
                      <span>Placed On</span>
                      <span>Items</span>
                      <span>Total</span>
                    </div>

                    <div className="productList__product">
                      <span>64643</span>
                      <span>21/09/23</span>
                      <div className="productI">
                        <img src={productImg1} alt="" />
                      </div>
                      <span>2400 BDT</span>
                    </div>

                    <div className="productList__product">
                      <span>64643</span>
                      <span>21/09/23</span>
                      <div className="productI">
                        <img src={productImg2} alt="" />
                      </div>
                      <span>2400 BDT</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : activeTab === "profile" ? (
            <div className="profileContent">
              <div className="profileDetails"></div>
            </div>
          ) : activeTab === "address" ? (
            <div className="profileContent">address</div>
          ) : null}

          {/* <div className="profileContent">
          <div className="profileContent__top">
            <div className="profileCard">
              <span className="profileCard__title">Personal Profile</span>

              <div className="profileCard__items">
                <span>Abdullah Al Zaber</span>
                <span>abdullahalzaber58@gmail.com</span>
                <span>Subscribe to our Newsletter</span>
              </div>
            </div>

            <div className="profileAddress__container">
              <div className="profileAddress__card">
                <span className="Address__cardTitle">Address Book</span>
                <span className="Address__cardSubTitle">
                  DEFAULT DELIVERY ADDRESS
                </span>

                <div className="address">
                  <span className="addressUser">Abdullah al jaber</span>
                  <span>
                    1005 Malibagh Chowdhurypara. Dhaka - Dhaka - South - Malibag
                    (+880) 1932493092
                  </span>
                </div>
              </div>

              <div className="profileAddress__card">
                <span className="Address__cardTitle">Billing Address</span>
                <span className="Address__cardSubTitle">
                  DEFAULT billing ADDRESS
                </span>

                <div className="address">
                  <span className="addressUser">Abdullah al jaber</span>
                  <span>
                    1005 Malibagh Chowdhurypara. Dhaka - Dhaka - South - Malibag
                    (+880) 1932493092
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="recentOrders">
            <div className="block productCheckout">
              <div className="productList">
                <div className="productList__header">
                  <span>Recent Orders</span>
                </div>

                <div className="productList__product">
                  <span>Order #</span>
                  <span>Placed On</span>
                  <span>Items</span>
                  <span>Total</span>
                </div>

                <div className="productList__product">
                  <span>646433637445915</span>
                  <span>21/09/2023</span>
                  <div className="productI">
                    <img src={productImg1} alt="" />
                  </div>
                  <span>2400 BDT</span>
                </div>

                <div className="productList__product">
                  <span>646433637445916</span>
                  <span>21/09/2023</span>
                  <div className="productI">
                    <img src={productImg2} alt="" />
                  </div>
                  <span>2400 BDT</span>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        </div>

        <Subscribe />
      </div>
    </AppLayout>
  );
};

export default ProfilePage;
