import React from "react";
import "./appLayout.scss";

// components
import Navbar from "../../navbar/Navbar";
import Footer from "../../footer/Footer";

const AppLayout = ({ children }) => {
  return (
    <div className="app-layout">
      <Navbar />
      <div className="app-layout__content">{children}</div>
      <Footer />
    </div>
  );
};

export default AppLayout;
