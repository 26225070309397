import React from "react";
import "./productView.scss";
import ProductCard from "../../cards/productCard/ProductCard";

const ProductView = ({ data, header }) => {
  return (
    <div className="productView__container">
      <div className="productView__header">
        <div className="productView__headerText">
          <span>{header} </span>
          <span>Products</span>
        </div>

        <div className="productView__viewBtn">
          <span>View All</span>
        </div>
      </div>

      <div className="productView__content">
        {data?.map((item) => {
          return <ProductCard item={item} />;
        })}
      </div>
    </div>
  );
};

export default ProductView;
